import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Button,
  getDefaultSortOrder,
  Icons,
  Modal,
  RefreshButton,
  Show, Table,
  Tag,
  TextField, Typography, useModal, useTable
} from "@pankod/refine-antd";
import { BaseRecord, useNavigation } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import React, { useState } from "react";
import DiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";
//const ReactJsonViewCompare = require("react-json-view-compare");

export const Notifications: React.FC = () => {

  const { tableProps, sorter } = useTable<BaseRecord>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.NOTIFICATIONS
  });
  const { show } = useNavigation();
  const { modalProps, show: showModal } = useModal({
    modalProps: {
      title: "Changes",
      //width: 600,
      okText: "Close",
      footer: null,
      maskClosable: true,
      closable: true,
      destroyOnClose: true,
    },
  });

  function rowClick(record: any) {
    return {
      onClick: () => {
        show(record?.operatedEntity, record?.operatedEntityId);
      },
    };
  }

  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  return (
    <Show
      title="Notifications"
      canDelete={false}
      headerProps={{
        backIcon: false,
        tags: <Tag color={"green"}>FYI Notifications</Tag>,
        extra: <><RefreshButton></RefreshButton></>
      }} >
      <Table {...tableProps}
        rowKey="rowKey"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total} notification(s)</Typography.Title>)
        }}
      >

        <Table.Column
          dataIndex="title"
          key="title"
          title="Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="operationPerformed"
          key="operationPerformed"
          title="Operation"
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
          defaultSortOrder={getDefaultSortOrder("operationPerformed", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="operationPerformedBy"
          key="operationPerformedBy"
          title="Updated By"
          render={(value: string) =>
            <ShowUserCard id={value} />
          }
          defaultSortOrder={getDefaultSortOrder("operationPerformedBy", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="operationPerformedAt"
          key="operationPerformedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("operationPerformedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          title="Actions"
          key="actions"
          fixed="right"
          className="wrklstbtns"
          render={(v, record: any) => (
            <>
              <Button
                style={{ marginRight: 2 }}
                onClick={() => {
                  setSelectedRecord(record);
                  showModal();
                }}
              >
                <Icons.DiffFilled />
                Changes
              </Button>
              <Button
                onClick={() => {
                  show(record.operatedEntity, record.operatedEntityId);
                }}
              >
                <Icons.EyeFilled />
                View
              </Button>
            </>
          )}
        />
      </Table>

      <Modal
        {...modalProps}
        width="auto"
      >
        {selectedRecord && <DiffViewer
          oldValue={selectedRecord?.oldJson != null ? JSON.parse(selectedRecord.oldJson) : {}}
          newValue={selectedRecord?.updatedJson != null ? JSON.parse(selectedRecord.updatedJson) : {}}
          splitView={true}
          compareMethod={DiffMethod.JSON}
          useDarkTheme={true}
          renderContent={(value) => <pre>{value}</pre>}
        />}
      </Modal>
    </Show>
  );
};
