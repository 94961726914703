import { IBaseProps, arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Card, Divider, EditButton, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { RenderTimelineModal, StateButtons } from "components/common";
import { RenderSocialButtons } from "components/common/socialButtons";
import { ShowUserCard } from "components/profile/showUserCard";
import dayjs from "dayjs";
import { IPrompt, IPromptMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, appendToAppTitle } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const PromptShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IPrompt>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data: metadata } = useOne<IPromptMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.PROMPT
  });
  const metaConfig = metadata?.data?.config;

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IPrompt;
  appendToAppTitle(record?.name);

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        title: record?.name,
        backIcon: true,
        tags: <Tag color="gold">{stringExtensions.capitalize(record?.stateManager.state) ?? "Loading"}</Tag>,
        extra: <>
          {record &&
            <CanAccess key={RESOURCE_PATH.PROMPT} resource={RESOURCE_PATH.PROMPT.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
              <Space>
                {metaConfig?.editableStates?.includes(record.stateManager.state) && <EditButton style={{ marginRight: 8 }} ></EditButton>}
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.PROMPT}
                  resourceToActId={record?.id || "0"}
                  resourceToNavigate={RESOURCE_PATH.WORKLIST}
                />
                {record && <RenderTimelineModal stateManager={record.stateManager} entityId={record?.id} entityName={RESOURCE_PATH.PROMPT} />}
              </Space>
            </CanAccess>
          }
        </>
      }}
    >
      {/* <Title level={3}>{record?.name}</Title> */}
      {record?.authorName &&
        <>
          <Text style={{ color: "GrayText" }}>By: </Text>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.authorName ?? []) ? record?.authorName?.map((e, i) => <ShowUserCard key={`author-${i}`} id={e}></ShowUserCard>) : "None"}
          </Space>
        </>
      }

      {record?.publishedDate &&
        <div>
          <Text style={{ color: "GrayText" }}>Published on: </Text>
          <Text style={{ color: "GrayText" }}>{dayjs(record.publishedDate).format('LLLL')}</Text>
        </div>
      }
      {<div style={{ marginTop: 10 }}>
        <RenderSocialButtons socialElementId={record?.socialElementId} activeMode={metaConfig?.socialElement?.activeStates?.includes(record?.stateManager?.state) ?? true} />
      </div>}

      <Divider></Divider>
      <Title level={5}>Intent</Title>
      <Text>{record?.description}</Text>

      {arrayExtensions.validateArray(record?.body ?? []) &&
        record?.body?.map((content, i) =>
          <Card
            style={{ marginBottom: 10, marginTop: 10 }}
            size="small"
            title={(arrayExtensions.validateArray(content?.applicableTo ?? []) ? content?.applicableTo?.map((e, i) => <Tag color="blue" key={`tag-${i}`}>{e}</Tag>) : "---")}
            key={`Prompt ${i + 1}`}
          >
            {/* <Title level={5}>Applicable Platforms</Title>
            {arrayExtensions.validateArray(content?.applicableTo ?? []) ? content?.applicableTo?.map((e, i) => <Tag color="blue" key={`tag-${i}`}>{e}</Tag>) : "---"} */}

            <Title level={5}>Prompt</Title>
            <div className="container" key={`para-${i}`} data-color-mode={getAppTheme()}>
              <MDEditor.Markdown source={content.prompt} />
            </div>
            {content.sampleOutput && <>
              <Title level={5} style={{ marginTop: 10 }}>Sample Output</Title>
              <div className="container" data-color-mode={getAppTheme()}>
                <MDEditor.Markdown source={content.sampleOutput} />
              </div>
            </>}
          </Card>


          // <div className="artcle" key={`art-${i}`}>            
          //   <Title level={5} style={{ marginTop: 10 }}>Prompt {i + 1}</Title>
          //   <div className="container" key={`para-${i}`} data-color-mode={getAppTheme()}>
          //     <MDEditor.Markdown source={content.prompt} />
          //   </div>
          //   {content.sampleOutput && <>
          //     <Title level={5} style={{ marginTop: 10 }}>Sample Output</Title>
          //     <div className="container" data-color-mode={getAppTheme()}>
          //       <MDEditor.Markdown source={content.sampleOutput} />
          //     </div></>}
          // </div>
        )
      }

      <Title level={5}>Applicable Platforms</Title>
      {arrayExtensions.validateArray(record?.applicableTo ?? []) ? record?.applicableTo?.map((e, i) => <Tag color="blue" key={`tag-${i}`}>{e}</Tag>) : ""}
    </Show>
  );
};