import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { AntdList, Button, Card, Icons, Modal, Table, Typography, useModal } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IChange, IChangeTracking } from "interfaces";
import { useState } from "react";
import DiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { DATAPROVIDER_CHANGETRACKING } from "scripts/site";

export const ChangeTrackingList: React.FC<{
    entityId?: string;
    entityName?: string;
}> = ({ entityId, entityName }) => {

    const { data, isLoading } = useOne<IChangeTracking[]>({
        dataProviderName: DATAPROVIDER_CHANGETRACKING,
        resource: entityName ?? "",
        id: entityId ?? 0,
        queryOptions: {
            enabled: !!entityName && !!entityId,
        }
    });

    const records = arrayExtensions.sortBy("operationPerformedAt", data?.data ?? []).reverse() as IChangeTracking[];

    const renderItem = (item: IChangeTracking, index: number) => {
        item.deltaParsed = item?.delta ? JSON.parse(item.delta) : null;
        // filter out the changes where blank values are updated
        const tableData = item?.deltaParsed?.filter((change: IChange) => (change.oldValue || change.value || change.changes));
        return (
            <AntdList.Item key={`ct-${index}`}>
                <Card title={<>
                    <Typography.Text style={{ color: "GrayText" }}>{stringExtensions.capitalize(item?.operationPerformed)} by </Typography.Text>
                    <ShowUserCard id={item?.operationPerformedBy} />
                    {displayRelativeDate(item?.operationPerformedAt)}
                </>
                }
                    extra={<Button type="link"
                        //style={{ marginRight: 2 }}
                        onClick={() => {
                            setSelectedRecord(item);
                            showModal();
                        }}
                        icon={<Icons.CodeOutlined />}
                    >
                        JSON
                    </Button>}
                >
                    <Table size="small" bordered={true}
                        rowKey={"key"}
                        dataSource={tableData}
                        style={{ marginLeft: 0, marginRight: 0, marginBottom: -1 }}
                        columns={[
                            {
                                title: 'Field',
                                dataIndex: 'key',
                                key: 'key',
                            },
                            {
                                title: 'Old Value',
                                dataIndex: 'oldValue',
                                key: 'oldValue',
                                render: (value, record) => {
                                    // check if the value is array or object
                                    if (arrayExtensions.validateArray(value)) {
                                        return value.map((v: any) => {
                                            if (typeof v === "object") {
                                                return <><Typography.Text>{JSON.stringify(v)}</Typography.Text><br /></>;
                                            }
                                            else {
                                                return <><Typography.Text>{v}</Typography.Text><br /></>;
                                            }
                                        });
                                    }

                                    if (value) {
                                        return value;
                                    }
                                    else {
                                        return record?.changes?.map((change: IChange) => {
                                            switch (change.type) {
                                                case "REMOVE":
                                                    return <><Typography.Text type="danger">Removed: {change.value}</Typography.Text><br /></>;
                                                case "UPDATE":
                                                    return <><Typography.Text>{change.oldValue}</Typography.Text><br /></>;
                                                default:
                                                    return "";
                                            }
                                        });
                                    }
                                }
                            },
                            {
                                title: 'New Value',
                                dataIndex: 'value',
                                key: 'value',
                                render: (value, record) => {
                                    // check if the value is array or object
                                    if (arrayExtensions.validateArray(value)) {
                                        return value.map((v: any) => {
                                            if (typeof v === "object") {
                                                return <><Typography.Text>{JSON.stringify(v)}</Typography.Text><br /></>;
                                            }
                                            else {
                                                return <><Typography.Text>{v}</Typography.Text><br /></>;
                                            }
                                        });
                                    }

                                    if (value) {
                                        return value;
                                    }
                                    else {
                                        return record?.changes?.map((change: IChange) => {
                                            switch (change.type) {
                                                case "UPDATE":
                                                    return <><Typography.Text>{change.value}</Typography.Text><br /></>;
                                                case "ADD":
                                                    return <><Typography.Text type="success">Added: {change.value}</Typography.Text><br /></>;
                                                default:
                                                    return "";
                                            }
                                        });
                                    }
                                }
                            },
                        ]}
                        pagination={false}
                    />
                </Card>
            </AntdList.Item >
        );
    };

    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const { modalProps, show: showModal } = useModal({
        modalProps: {
            title: "Changes",
            //width: 600,
            okText: "Close",
            footer: null,
            maskClosable: true,
            closable: true,
            destroyOnClose: true,
        },
    });
    return (
        <div className="changetracking">
            <AntdList itemLayout="vertical"
                grid={{
                    gutter: 10,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                }}
                dataSource={records}
                loading={isLoading}
                renderItem={renderItem}
                pagination={{
                    pageSize: 2,
                    position: "bottom",
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
                }}
            >
            </AntdList>
            <Modal
                {...modalProps}
                width="auto"
            >
                {selectedRecord && <DiffViewer
                    oldValue={selectedRecord?.oldJson ? JSON.parse(selectedRecord.oldJson) : {}}
                    newValue={selectedRecord?.newJson ? JSON.parse(selectedRecord.newJson) : {}}
                    splitView={true}
                    compareMethod={DiffMethod.JSON}
                    useDarkTheme={true}
                    renderContent={(value) => <pre>{value}</pre>} />}
            </Modal>
        </div>
    );
}