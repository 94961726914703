import { IBaseProps, arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Col, Divider, EditButton, Icons, Row, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import { RenderTimelineModal, StateButtons } from "components/common";
import { RenderSocialButtons } from "components/common/socialButtons";
import { ShowUserCard } from "components/profile/showUserCard";
import { LookupButton } from "components/utils/lookupButton";
import dayjs from "dayjs";
import { IOffering, IOfferingMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, appendToAppTitle } from "scripts/site";

export const OfferingShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IOffering>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data: metadata } = useOne<IOfferingMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.OFFERING
  });
  const metaConfig = metadata?.data?.config;

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IOffering;
  appendToAppTitle(record?.name);

  const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        title: record?.title,
        backIcon: true,
        tags: <Tag color="gold">{stringExtensions.capitalize(record?.stateManager.state) ?? "Loading"}</Tag>,
        extra: <>
          {record &&
            <CanAccess key={RESOURCE_PATH.OFFERING} resource={RESOURCE_PATH.OFFERING.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
              <Space>
                {metaConfig?.editableStates?.includes(record.stateManager.state) && <EditButton style={{ marginRight: 8 }} ></EditButton>}
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.OFFERING}
                  resourceToActId={record?.id || "0"}
                  resourceToNavigate={RESOURCE_PATH.WORKLIST}
                />
                {record && <RenderTimelineModal stateManager={record.stateManager} entityId={record?.id} entityName={RESOURCE_PATH.OFFERING} />}
              </Space>
            </CanAccess>
          }
        </>
      }}
    >
      {/* <Title level={3}>{record?.name}</Title> */}
      {record?.authorName &&
        <>
          <Text style={{ color: "GrayText" }}>By: </Text>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.authorName ?? []) ? record?.authorName?.map((e, i) => <ShowUserCard key={`author-${i}`} id={e}></ShowUserCard>) : "None"}
          </Space>
        </>
      }

      {record?.publishedDate &&
        <div>
          <Text style={{ color: "GrayText" }}>Published on: </Text>
          <Text style={{ color: "GrayText" }}>{dayjs(record.publishedDate).format('LLLL')}</Text>
        </div>
      }
      {<div style={{ marginTop: 10 }}>
        <RenderSocialButtons socialElementId={record?.socialElementId} activeMode={metaConfig?.socialElement?.activeStates?.includes(record?.stateManager?.state) ?? true} />
      </div>}
      {<Divider></Divider>}
      <Title level={5}>Description</Title>
      <Text>{record?.description}</Text>

      <Title level={5}>Value Proposition</Title>
      <Text>{record?.valueproposition}</Text>

      <Row gutter={rowGutter}>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Offering Type</Title>
          <Text>{record?.offeringtype}</Text>
        </Col>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Licensing Model</Title>
          <Text>{record?.licensingmodel}</Text>
        </Col>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Number of Production Implementations</Title>
          <Text>{record?.prodimplementations}</Text>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Service Lines</Title>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.serviceLineIds ?? []) ? record?.serviceLineIds?.map((e, i) => <LookupButton resource={RESOURCE_PATH.SERVICELINE} noClick key={`other-${i}`} id={e}></LookupButton>) : "None"}
          </Space>
        </Col>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Value Dimensions</Title>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.valueDimensions ?? []) ? record?.valueDimensions?.map((e, i) => <Tag color="blue">{e}</Tag>) : "None"}
          </Space>
        </Col>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Fulfilled via Technology</Title>
          <Space wrap split={<Divider type="vertical" />} >
            <Text>{record?.technology}</Text>
          </Space>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Owning Unit</Title>
          <Text>{record?.owningunit}</Text>
        </Col>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Owning Account</Title>
          <Text>{record?.owningaccount}</Text>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Point of Contact</Title>
          <Text>{record?.pointofcontact}</Text>
        </Col>
        <Col className="gutter-row" {...threeColumnLayout}>
          <Title style={{ marginTop: 15 }} level={5}>Point of Contact (Email)</Title>
          <Text>{record?.pointofcontactemail}</Text>
        </Col>
      </Row>

      <Title style={{ marginTop: 15 }} level={5}>Account SME(s)</Title>
      {record?.accountsme &&
        <>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.accountsme ?? []) ? record?.accountsme?.map((e, i) => <ShowUserCard key={`author-${i}`} id={e}></ShowUserCard>) : "None"}
          </Space>
        </>
      }

      {record?.offeringcollateral && record?.offeringcollateral.length > 0 &&
        <>
          <Divider orientation="left">Offering Collaterals</Divider>
          <Space wrap split={<Divider type="vertical" />} >
            {record?.offeringcollateral?.map((e, i) => <Button key="offeringcollateral" style={{ marginLeft: 8 }} icon={<Icons.DownloadOutlined />} title="View Offering Collateral" onClick={() => window.open(e.url, "_blank")}>{e.name}</Button>)}
          </Space>
        </>
      }
    </Show>
  );
};