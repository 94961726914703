import { Button, Icons, Modal, useModal } from "@pankod/refine-antd";
import { HistoryTab } from "components/common/historyTab";
import { IStateManager } from "interfaces";

export declare type TimeLineProps =
    {
        stateManager: IStateManager,
        mode?: "standard" | "worklist";
        entityId?: string;
        entityName?: string;
    }

export const RenderTimelineModal: React.FC<TimeLineProps> = ({ stateManager, mode = "standard", entityId, entityName }) => {
    const { modalProps, show, close } = useModal()

    return (
        <>
            {mode === "worklist" ?
                <div className="st-btn-dv" onClick={show}>
                    <Icons.HistoryOutlined /> History
                </div>
                :
                <Button onClick={show} title="History" icon={<Icons.HistoryOutlined />}></Button>
            }
            <Modal
                {...modalProps}
                footer={
                    <Button type="primary" key="back" onClick={close}>
                        Return
                    </Button>
                }
                width='80%'
            >
                <HistoryTab
                    stateManager={stateManager}
                    tabPosition={'top'}
                    modalView={true}
                    entityId={entityId} entityName={entityName}
                />
            </Modal>
        </>
    );
};