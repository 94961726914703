import { Icons, Tabs } from "@pankod/refine-antd";
import { useCan } from "@pankod/refine-core";
import { HistoryTable } from "components/common/historyTable";
import { IStateManager } from "interfaces";
import { DATAPROVIDER_CHANGETRACKING } from "scripts/site";
import { ChangeTrackingList } from "./changeTrackingList";

type TabPosition = 'left' | 'right' | 'top' | 'bottom';
interface IHistoryTab {
    stateManager: IStateManager,
    tabPosition: TabPosition,
    modalView?: boolean,
    entityId?: string;
    entityName?: string;
}

export const HistoryTab: React.FC<IHistoryTab> = ({ stateManager, tabPosition = 'top', modalView = false, entityId, entityName }) => {

    let restrictHeight: string = modalView ? "488px" : "auto"

    const canChangeTracking = useCan({
        resource: entityName ?? "",
        action: "get",
        params: {
            dataProviderName: DATAPROVIDER_CHANGETRACKING,
            id: entityId
        },
        queryOptions: {
            enabled: (!!entityId && !!entityName)
        }
    });

    const tabItems: any[] = [
        {
            label: <span><Icons.TableOutlined />State Changes</span>,
            key: "tableViewTab",
            forceRender: true,
            style: { overflowY: "auto", overflowX: 'hidden', height: restrictHeight },
            children: <HistoryTable {...stateManager} key="historyTable" />
        },
        // {
        //     label: <span><Icons.NodeIndexOutlined />State Timeline</span>,
        //     key: "timelineViewTab",
        //     //forceRender: true,
        //     style: { overflowY: "auto", overflowX: 'hidden', height: restrictHeight },
        //     children: <TimelineView {...stateManager} key="timelineView" />
        // },
    ];

    if (canChangeTracking?.data?.can) {
        tabItems.push({
            label: <span><Icons.CodeOutlined />Change Log</span>,
            key: "changelogTab",
            //forceRender: true,
            //style: { overflowY: "auto", overflowX: 'hidden', height: restrictHeight },
            children: <ChangeTrackingList entityId={entityId} entityName={entityName} />
        });
    }

    return (<Tabs
        tabPosition={tabPosition}
        items={tabItems}
    />);
};