import { Card, Col, Collapse, DateField, DatePicker, Divider, Form, Icon, Icons, List, Radio, Row, SaveButton, Select, Table, Typography, useSelect, useTable } from "@pankod/refine-antd";
import {
  CrudFilters,
  IResourceComponentsProps
} from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { GenAISVG } from "components/svgs/genAISvg";
import dayjs from "dayjs";
import { IAiSummaryResult, ILookup } from "interfaces";
import React, { useState } from "react";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { DATAPROVIDER_AI, DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const AiSummaryList: React.FC<IResourceComponentsProps> = () => {

  // define state variable
  const [resource, setResource] = useState<string>(RESOURCE_PATH.INTAKE_ACTIVITY);
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(false);

  const { searchFormProps, tableQueryResult } = useTable<IAiSummaryResult>({
    dataProviderName: DATAPROVIDER_AI,
    resource: resource,
    permanentFilter: [
      {
        field: "_aiAction",
        operator: "eq",
        value: "summarize"
      }
    ],
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    //hasPagination: false,
    onSearch: (values: any) => {
      const filters: CrudFilters = [];
      const { range, updatedBy, tone, length } = values;
      const [startDate, endDate] = range || [];

      if (startDate && endDate) {
        filters.push({
          field: "activitydate",
          operator: "gte",
          value: dayjs(startDate).startOf("day").toISOString()
        });
        filters.push({
          field: "activitydate",
          operator: "lte",
          value: dayjs(endDate).endOf("day").toISOString()
        });
      }
      filters.push({
        field: "updatedBy",
        operator: "eq",
        value: updatedBy
      });
      filters.push({
        field: "_tone",
        operator: "eq",
        value: tone
      });
      filters.push({
        field: "_length",
        operator: "eq",
        value: length
      });
      return filters;
    },
    queryOptions: {
      enabled: !!resource && isQueryEnabled,
      //enabled: false,
    },

  });

  const { selectProps: userList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "stateManager.state",
      operator: "eq",
      value: "active"
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  const summary = (tableQueryResult?.data?.data as any) as IAiSummaryResult;

  return (
    <List headerProps={{ title: "Helium IQ", subTitle: "Generate insights using AI" }}
      headerButtons={[]}
    >
      <Card>
        <Form {...searchFormProps} layout="vertical" autoComplete="off"
          initialValues={{
            entity: RESOURCE_PATH.INTAKE_ACTIVITY,
            tone: "formal",
            length: "detailed",
          }}
        >
          <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Form.Item
                label="Generate insights for"
                name="entity"
                rules={[
                  {
                    required: true,
                    whitespace: true
                  }
                ]}
              >
                <Radio.Group optionType="button" buttonStyle="solid" value={resource}
                  onChange={(e) => setResource(e.target.value)}
                  options={[
                    { label: "Engage AIT", value: RESOURCE_PATH.INTAKE_ACTIVITY },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Form.Item
                label="Tone"
                name="tone"
              >
                <Radio.Group optionType="button" buttonStyle="solid"
                  options={[
                    { label: <><Icons.ShoppingOutlined /> Formal</>, value: "formal" },
                    { label: <><Icons.SmileOutlined /> Casual</>, value: "casual" },
                    { label: <><Icons.AimOutlined /> Direct</>, value: "direct" },
                    { label: <><Icons.EditOutlined /> Poem</>, value: "poem" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Form.Item
                label="Length"
                name="length"
              >
                <Radio.Group optionType="button" buttonStyle="solid"
                  options={[
                    { label: <><Icons.MenuOutlined /> Detailed</>, value: "detailed" },
                    { label: <><Icons.LineOutlined /> Brief</>, value: "brief" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            {resource === RESOURCE_PATH.INTAKE_ACTIVITY && <>
              <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                  label="Date Range"
                  name="range"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      type: 'array'
                    }
                  ]}
                >
                  <DatePicker.RangePicker presets={[
                    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
                    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
                    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
                    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
                  ]}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                  label="Updated By"
                  name="updatedBy"
                  tooltip="To look up any missing name type the name of the person and then select"
                >
                  <Select allowClear mode="multiple" //style={{ maxWidth: "286px" }}
                    {...userList}
                  >
                  </Select>
                </Form.Item>
              </Col>
            </>}
          </Row>

          <SaveButton icon={<Icon component={GenAISVG}></Icon>} htmlType="submit" type="primary"
            loading={tableQueryResult.isFetching}
            onClick={() => {
              searchFormProps?.form?.validateFields().then(async () => {
                setIsQueryEnabled(true);
                searchFormProps?.form?.submit();
              });
            }}>Generate</SaveButton>
        </Form>
      </Card>

      {(summary || tableQueryResult.isFetching) && <>
        <Card style={{ marginTop: "25px" }}
          loading={tableQueryResult?.isFetching} size="small"
          title={<><Icon component={GenAISVG}></Icon><Typography.Text style={{ marginLeft: 10, fontSize: 20 }} >Insights</Typography.Text></>}
          extra={<Typography.Text type="secondary">AI-generated content may be incorrect</Typography.Text>}>
          <MDEditor.Markdown source={summary?.result ?? summary?.message} />
          <Divider style={{ marginBottom: 10 }} />
          <Typography.Text type="secondary">
            {summary?.usage?.total_tokens
              ? `Total ${summary?.usage?.total_tokens} tokens | `
              : ""}
            {summary?.timestamp
              ? `Generated on ${dayjs(summary?.timestamp).format("lll")}`
              : ""}
          </Typography.Text>
        </Card>

        {summary?.data && <Collapse style={{ marginTop: 10 }} size="small">
          <Collapse.Panel header="Data" key="1">
            <Table dataSource={summary?.data} size="small" loading={tableQueryResult.isFetching}
            //pagination={false}
            >
              <Table.Column title="Intake Title" dataIndex="intakeTitle" key="intakeTitle" />
              <Table.Column title="Activity Title" dataIndex="name" key="name" />
              <Table.Column title="Description" dataIndex="description" key="description" />
              <Table.Column
                dataIndex="activitydate"
                key="activitydate"
                title="Activity Date"
                render={(value) => <DateField value={value ?? 0} format="lll" />} />
            </Table>
          </Collapse.Panel>
        </Collapse>
        }
      </>
      }
    </List>
  );
};
