import {
  arrayExtensions,
  stringExtensions,
} from "@architecture-innovation-transformation/lib-common";
import { FormInstance, useTable } from "@pankod/refine-antd";
import { BaseRecord, CrudFilters, HttpError } from "@pankod/refine-core";
import simpleRestDataProvider from "@pankod/refine-simple-rest";
import { ai } from "appInsightLoad";
import { Mutex } from "async-mutex";
import dayjs, { Dayjs } from "dayjs";
import { POST_LOGIN_REDIRECT } from "hashRouter/routeProvider";
import {
  IAuthZ,
  IEvaluationModel,
  IMetric,
  IMetricValue,
  IRecordMetricValue,
  IState,
  IStateManager,
  IUser,
  IWorklistFilterVariables,
  nextState
} from "interfaces";
import { useEffect, useState } from "react";

export const API_PATH = "/api/v1";
export const AUTH_ME = "/.auth/me";
export const MY_PROFILE = API_PATH + "/my/profile";
const UPDATE_PROFILE = API_PATH + "/update/profile";
const AUTHZ = API_PATH + "/authz/metadata";
const ATTACHMENT = API_PATH + "/attachment";

export const API_LOOKUP = "/lookup";
export const API_SYSTEM = "/system";
export const API_READ = "/read";
export const API_COUNT = "/count";
export const API_DISTINCT = "/distinct";
export const API_CREATE = "/create";
export const API_UPDATE = "/update";
export const API_DELETE = "/delete";
export const API_UPSERT = "/upsert";
export const API_STATE = "/state";
export const API_MY = "/my";
export const API_AUTHZ = "/authz";
export const API_UPLOAD = "/upload";
export const API_SUBSCRIBE = "/subscribe";
export const API_OUTREACH = "/outreach";
export const API_REVIEW = "/review";
export const API_EXPORT = "/export";
export const API_REGISTER = "/register";
export const API_WORKLIST = "/worklist";
export const API_SOCIAL = "/social";
export const API_CONFIGSET = "/configset";
export const API_SEARCH = "/search";
export const API_AI = "/ai";
export const API_CHANGETRACKING = "/changetracking";

export const DATAPROVIDER_AUTHZ = "authz";
export const DATAPROVIDER_LOOKUP = "lookup";
export const DATAPROVIDER_SYSTEM = "system";
export const DATAPROVIDER_DEFAULT = "default";
export const DATAPROVIDER_READ = "read";
export const DATAPROVIDER_COUNT = "count";
export const DATAPROVIDER_DISTINCT = "distinct";
export const DATAPROVIDER_CREATE = "create";
export const DATAPROVIDER_UPDATE = "update";
export const DATAPROVIDER_DELETE = "delete";
export const DATAPROVIDER_UPSERT = "upsert";
export const DATAPROVIDER_UPLOAD = "upload";
export const DATAPROVIDER_SUBSCRIBE = "subscribe";
export const DATAPROVIDER_OUTREACH = "outreach";
export const DATAPROVIDER_REVIEW = "review";
export const DATAPROVIDER_EXPORT = "export";
export const DATAPROVIDER_REGISTER = "register";
export const DATAPROVIDER_WORKLIST = "worklist";
export const DATAPROVIDER_SOCIAL = "social";
export const DATAPROVIDER_CONFIGSET = "configset";
export const DATAPROVIDER_SEARCH = "search";
export const DATAPROVIDER_AI = "ai";
export const DATAPROVIDER_CHANGETRACKING = "changetracking";

export const AI_ACTION_FILEPARSER = "fileparser";

export const EVALUATIONMODEL_AGGREGATION_AVERAGE = "avg";
export const EVALUATIONMODEL_AGGREGATION_MIN = "min";
export const EVALUATIONMODEL_AGGREGATION_MAX = "max";

// Milliseconds
export const CACHE_DURATION = 10 * 60 * 1000;
export const STALE_DURATION = 8 * 60 * 1000;
export const CACHE_KEY_PREFIX = "Helium";
export const STATE_ACTION = "state";
export const FETCH_ACTION = "fetch";

// Authentication and Authorization
export const AAD_LOGIN = "/.auth/login/aad";
export const AAD_LOGOUT = "/.auth/logout";
export const AAD_PURGE = "/.auth/purge/aad";

// Helium Logo
export const HELIUM_LOGO_PATH = "/helium-logo-1.png";
export const OPENAI_LOGO_PATH = "/logo-open-ai.png";
export const TCS_LOGO_PATH = "/tcs-logo-2.png";
export const THINK_GEN_AI_LOGO_BLACK = "/thinkgenai-black.png";
export const THINK_GEN_AI_LOGO_WHITE = "/thinkgenai-white.png";

export const RESOURCE_PATH = {
  ABOUT: "about",
  STORIES: "stories",
  CHAT: "chat",
  ASSESSMENT: "assessment",
  ENGAGEMENTPROGRAM: "engagementprogram",
  ENGAGEMENTSOLUTION: "engagementsolution",
  ASSET: "asset",
  CONTRACT: "contract",
  ASSOCIATEMAPPING: "associatemapping",
  DASHBOARD: "dashboard",
  ENGAGEMENT: "engagement",
  PROGRAM: "program",
  SCENARIO: "scenario",
  SERVICELINE: "serviceline",
  ENGAGEMENTTYPE: "engagementtype",
  EVALUATION: "evaluation",
  EVALUATIONMODEL: "evaluationmodel",
  LOCATION: "location",
  METADATA: "metadata",
  NOTIFICATIONS: "notification",
  PROFILE: "profile",
  PROJECT: "project",
  ROLE: "role",
  SYSTEM: "system",
  USERS: "user",
  PEOPLE: "people",
  GROUPS: "group",
  WORKLIST: "worklist",
  REQUEST: "request",
  EVENT: "event",
  INFO_SECURITY: "infosecurity",
  INFO_ASPIRE: "infoaspire",
  INFO_PROCESS: "infoprocess",
  INFO_ANNOUNCEMENTS: "infoannouncements",
  INFO_ABMDESK: "infoabmdesk",
  INFO_PODCAST: "podcast",
  EXPERT_OUTREACH: "expertoutreach",
  LIST: "list",
  REVIEW_TAGS: "tagsreview",
  CANDIDATE: "candidate",
  RESOURCINGREQUEST: "resourcingrequest",
  POSITION: "position",
  CANDIDATEEVALUATION: "candidateevaluation",
  COUNTRY: "country",
  ASKTHEEXPERT: "askexpert",
  SPACES: "spaces",
  BUILDING: "building",
  ZONE: "zone",
  ROOM: "room",
  RESERVATION: "reservation",
  AVAILABILITY: "availability",
  ARTICLE: "article",
  PROMPT: "prompt",
  SOCIAL: "social",
  OFFERING: "offering",
  INTAKE: "intake",
  INTAKE_ACTIVITY: "intakeactivity",
  IQ: "iq"
};

export const SELECTION_LISTS = {
  HOBBIES: "hobbies",
  TECHNICAL_EXPERTISE: "technicalexpertise",
  FUNCTIONAL_EXPERTISE: "functionalexpertise",
  TECHNICAL_CERTIFICATIONS: "technicalcertificates",
  FUNCTIONAL_CERTIFICATIONS: "functionalcertificates",
  AI_SUGGESTIONS: "aisuggestions",
  AI_SUGGESTIONS_REVIEWED: "aisuggestionsreviewed",
  RESOURCING_ROLES: "resourcingroles"
};

export const dataProviders: any = {
  [DATAPROVIDER_DEFAULT]: simpleRestDataProvider(API_PATH + API_READ),
  [DATAPROVIDER_READ]: simpleRestDataProvider(API_PATH + API_READ),
  [DATAPROVIDER_COUNT]: simpleRestDataProvider(API_PATH + API_COUNT),
  [DATAPROVIDER_DISTINCT]: simpleRestDataProvider(API_PATH + API_DISTINCT),
  [DATAPROVIDER_CREATE]: simpleRestDataProvider(API_PATH + API_CREATE),
  [DATAPROVIDER_UPDATE]: simpleRestDataProvider(API_PATH + API_UPDATE),
  [DATAPROVIDER_DELETE]: simpleRestDataProvider(API_PATH + API_DELETE),
  [DATAPROVIDER_UPSERT]: simpleRestDataProvider(API_PATH + API_UPSERT),
  [DATAPROVIDER_AUTHZ]: simpleRestDataProvider(API_PATH + API_AUTHZ),
  [DATAPROVIDER_LOOKUP]: simpleRestDataProvider(API_PATH + API_LOOKUP),
  [DATAPROVIDER_SYSTEM]: simpleRestDataProvider(API_PATH + API_SYSTEM),
  [DATAPROVIDER_UPLOAD]: simpleRestDataProvider(API_PATH + API_UPLOAD),
  [DATAPROVIDER_SUBSCRIBE]: simpleRestDataProvider(API_PATH + API_SUBSCRIBE),
  [DATAPROVIDER_OUTREACH]: simpleRestDataProvider(API_PATH + API_OUTREACH),
  [DATAPROVIDER_REVIEW]: simpleRestDataProvider(API_PATH + API_REVIEW),
  [DATAPROVIDER_EXPORT]: simpleRestDataProvider(API_PATH + API_EXPORT),
  [DATAPROVIDER_REGISTER]: simpleRestDataProvider(API_PATH + API_REGISTER),
  [DATAPROVIDER_WORKLIST]: simpleRestDataProvider(API_PATH + API_WORKLIST),
  [DATAPROVIDER_SOCIAL]: simpleRestDataProvider(API_PATH + API_SOCIAL),
  [DATAPROVIDER_CONFIGSET]: simpleRestDataProvider(API_PATH + API_CONFIGSET),
  [DATAPROVIDER_SEARCH]: simpleRestDataProvider(API_PATH + API_SEARCH),
  [DATAPROVIDER_AI]: simpleRestDataProvider(API_PATH + API_AI),
  [DATAPROVIDER_CHANGETRACKING]: simpleRestDataProvider(API_PATH + API_CHANGETRACKING),
};
export const allowedResources: string[] = [];

const actionMap = [
  {
    api: "get",
    web: "list",
  },
  {
    api: "get",
    web: "show",
  },
  {
    api: "post",
    web: "create",
  },
  {
    api: "delete",
    web: "delete",
  },
  {
    api: "patch",
    web: "edit",
  },
];

export const greyStyle = { color: "GrayText" };

export function webToApiAction(webAction: string): string {
  return (
    actionMap.find((m) => stringExtensions.stringEquals(m.web, webAction))
      ?.api ?? webAction
  );
}

export function apiToWebAction(apiAction: string): string {
  return (
    actionMap.find((m) => stringExtensions.stringEquals(m.api, apiAction))
      ?.web ?? apiAction
  );
}

// Local Storage - Session Storage
export function removeLocalSessionStorage(key: string) {
  sessionStorage.removeItem(CACHE_KEY_PREFIX + key);
}

export function clearLocalSessionStorage(all: boolean = true) {
  if (all) {
    sessionStorage.clear();
  } else {
    [...Array(sessionStorage.length)]
      .map((_, i) => sessionStorage.key(i))
      .filter((key) => key?.startsWith(CACHE_KEY_PREFIX))
      .forEach((key) => (key ? sessionStorage.removeItem(key) : () => { }));
  }
  ai.clearAuthenticatedUserContext();
}

export function setSessionStorageWithExpiry(
  key: string,
  value: any,
  ttl: number
) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  sessionStorage.setItem(cacheKey, JSON.stringify(item));
}

export function getSessionStorageWithExpiry(key: string) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const itemStr = sessionStorage.getItem(cacheKey);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(cacheKey);
    return null;
  }
  return item.value;
}

// Local Storage - Outside Session
export function removeLocalStorage(key: string) {
  localStorage.removeItem(CACHE_KEY_PREFIX + key);
}

export function clearLocalStorage(all: boolean = true) {
  if (all) {
    localStorage.clear();
  } else {
    [...Array(localStorage.length)]
      .map((_, i) => localStorage.key(i))
      .filter((key) => key?.startsWith(CACHE_KEY_PREFIX))
      .forEach((key) => (key ? localStorage.removeItem(key) : () => { }));
  }
}

export function setLocalStorage(key: string, value: any) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    timeStamp: Math.floor(now.getTime() / 1000),
  };
  localStorage.setItem(cacheKey, JSON.stringify(item));
}

export function getLocalStorage(key: string, checkTimeStamp?: number) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const itemStr = localStorage.getItem(cacheKey);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  // compare the expiry time of the item with the current time
  if (checkTimeStamp && item.timeStamp < checkTimeStamp) {
    // If the item in local storage is modified later then retreived
    localStorage.removeItem(cacheKey);
    return null;
  }
  return item.value;
}

export function syncWait(ms: number) {
  const end = Date.now() + ms;
  while (Date.now() < end) {
    continue;
  }
}

export type resParser = (apiRes: Response) => any;
const defResParser = async (apiRes: Response) => {
  if (apiRes?.headers?.get("content-length") !== "0") {
    return await apiRes.json();
  }
  return null;
};
const deferrorParser = async (apiRes: Response) => { return null };

const mutexList = new Map<string, Mutex>();

export async function apiCallWithMutex(
  apiPath: string,
  responseParser: resParser = defResParser,
  errorParser: resParser = deferrorParser,
  dontUseCache = false
) {
  let clientLock = mutexList.get(apiPath);
  if (!clientLock) {
    clientLock = new Mutex();
    mutexList.set(apiPath, clientLock);
  }

  let release = await clientLock.acquire();
  const result = await apiCallWithLocalCache(apiPath, responseParser, errorParser, dontUseCache);
  release();
  return result;
}

export async function apiCallWithLocalCache(
  apiPath: string,
  responseParser: resParser = defResParser,
  errorParser: resParser = deferrorParser,
  dontUseCache = false
) {
  if (dontUseCache) {
    sessionStorage.removeItem(CACHE_KEY_PREFIX + apiPath);
  } else {
    // Check local storage
    const cachedResponse = getSessionStorageWithExpiry(apiPath);
    if (cachedResponse) {
      //console.log("Serving from Cache - " + apiPath);
      return cachedResponse;
    }
  }

  // Item not found in cache
  // console.log("Hitting API - " + apiPath);
  const response = await fetch(apiPath, {
    method: "GET",
    headers: {
      "x-helium-user-time": new Date().toString(),
    }
  });

  if (response.ok) {
    const resJson = await responseParser(response);
    // Store the response in cache for 10 min if its not null.
    if (resJson && !dontUseCache) {
      //console.log("Storing in Cache - " + apiPath);
      setSessionStorageWithExpiry(apiPath, resJson, CACHE_DURATION);
    }
    return resJson;
  }
  return await errorParser(response);;
}

export async function fetchClientPrincipal() {
  // Retrieve response from /.auth/me
  // Retrieve the clientPrincipal (current user)
  // Skip Cache for all auth me calls

  const clientPrincipal = await apiCallWithMutex(
    AUTH_ME,
    async (apiRes) => (await apiRes?.json())?.clientPrincipal,
    deferrorParser
  );
  if (clientPrincipal) {
    ai.setAuthenticatedUserContext(
      clientPrincipal.userId,
      clientPrincipal.userDetails?.split("@")[1],
      true
    );
  }
  else {
    clearLocalSessionStorage(false);
  }
  return clientPrincipal;
}

export async function performLogin() {
  if (!await fetchClientPrincipal()) {
    let loginURL = AAD_LOGIN;

    // Check if we are been redirected for specific page
    let redirectURL = getRedirectionURL(window.location.hash);
    if (redirectURL) {
      loginURL += POST_LOGIN_REDIRECT + redirectURL;
    }

    window.location.href = loginURL
    return Promise.reject();
  }
  return Promise.resolve();
}

let aadPurgeCompleted = false;
export async function purgeAadData(userId: string, updateProfile = true) {
  // Check if AAD Data Purge is required
  if (!aadPurgeCompleted) {
    // Check if AAD Data Purge is already done
    let clientLock = mutexList.get(AAD_PURGE);
    if (!clientLock) {
      clientLock = new Mutex();
      mutexList.set(AAD_PURGE, clientLock);
    }

    let release = await clientLock.acquire();

    const apiRes = await fetch(AAD_PURGE);
    if (apiRes.ok) {
      aadPurgeCompleted = true;
      if (updateProfile) {
        // Store the purge status in database.
        await fetch(UPDATE_PROFILE + "/" + userId, {
          method: "PATCH",
          body: JSON.stringify({
            _skipTimeStamp: true,
            lastAadPurgeAt: new Date().toISOString(),
          }),
        });
      }
      console.log("AAD Data purge completed.");
    }
    else {
      console.log("AAD Data purge failed.");
    }

    release();
  }
}

export async function fetchAuthZCheck(
  resource: string,
  apiAction: string,
  dataProviderName: string = DATAPROVIDER_DEFAULT,
  params?: any
): Promise<any> {
  let apiPath =
    (dataProviders[dataProviderName]?.getApiUrl() ?? API_PATH + API_READ) + "/";

  apiPath += resource.toLowerCase() + "/";
  if (params?.id) {
    apiPath += params?.id + "/";
  }
  apiPath += apiAction.toLowerCase() + "?authZCheck=true";

  // convert params to query string
  if (params) {
    const query = Object.keys(params).filter(k => k !== "id" && k !== "dataProviderName")
      .map((key) => key + "=" + params[key])
      .join("&");
    if (query) {
      apiPath += "&" + query;
    }
  }

  return await apiCallWithLocalCache(apiPath);
}

export async function fetchAuthZ(): Promise<IAuthZ[]> {
  return await apiCallWithMutex(AUTHZ);
}

export async function fetchUserIdentity() {
  return await apiCallWithMutex(MY_PROFILE, async (apiRes) => {
    return {
      ok: apiRes?.ok,
      json: await apiRes?.json()
    };
  }, async (apiRes) => {
    return {
      ok: apiRes?.ok,
      json: await apiRes?.json()
    };
  });
}

export function matchRbac(
  permission: string[],
  resource: string,
  action: string
): boolean {
  if (arrayExtensions.validateArray(permission)) {
    return permission.some((perm) => {
      // Split the perm array by /. Should have exactly 3 parts.
      const permArr = perm.split("/");
      if (permArr && permArr.length === 3) {
        const entity = permArr[0];
        const scope = permArr[1];
        const capability = permArr[2];

        return (
          (entity === "*" || stringExtensions.stringEquals(resource, entity)) &&
          scope === "*" && // implement scope level logic here
          (capability === "*" ||
            stringExtensions.stringEquals(action, capability))
        );
      }
      return false;
    });
  }
  return false;
}

export function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split("&")
      .reduce((params: any, param) => {
        let [key, value] = param.split("=");
        params[key] = value
          ? decodeURIComponent(value.replace(/\+/g, " "))
          : "";
        return params;
      }, {})
    : {};
}

export function calculateScore(record: IEvaluationModel) {
  if (record) {
    record.groups.forEach((grp) => {
      grp.questions.forEach((ques) => {
        let checkedOptions = ques.multiSelect
          ? ques.options.filter((o) => o.checked)
          : ques.options.filter((o) => o.name === ques.selectedOption);

        if (
          (ques.multiSelect && checkedOptions.length > 0) ||
          (!ques.multiSelect && checkedOptions.length === 1)
        ) {
          const checkedSum = checkedOptions
            .map((i) => i.weightage ?? 1)
            .reduce((partialSum, a) => partialSum + a, 0);

          const total = ques.multiSelect
            ? ques.options
              .map((i) => i.weightage ?? 1)
              .reduce((partialSum, a) => partialSum + a, 0)
            : Math.max(...ques.options.map((i) => i.weightage ?? 1));

          ques.score = checkedSum / total;
        } else {
          ques.score = 0;
        }
      });

      const checkedSum = grp.questions
        .map((i) => (i.score ?? 0) * (i.weightage ?? 1))
        .reduce((partialSum, a) => partialSum + a, 0);

      const total = grp.questions
        .map((i) => i.weightage ?? 1)
        .reduce((partialSum, a) => partialSum + a, 0);
      grp.score = checkedSum / total;
    });

    if (!record.aggregation) {
      record.aggregation = EVALUATIONMODEL_AGGREGATION_AVERAGE;
    }

    record.output.maturityLevel = getMaturityLevel(record);

    switch (record.aggregation) {
      case EVALUATIONMODEL_AGGREGATION_AVERAGE:
        const checkedSum = record.groups
          .map((i) => (i.score ?? 0) * (i.weightage ?? 1))
          .reduce((partialSum, a) => partialSum + a, 0);

        const total = record.groups
          .map((i) => i.weightage ?? 1)
          .reduce((partialSum, a) => partialSum + a, 0);

        record.output.score = checkedSum / total;
        break;
      case EVALUATIONMODEL_AGGREGATION_MAX:
        record.output.score = Math.max(
          ...record.groups.map((i) => i.score ?? 1)
        );
        break;
      case EVALUATIONMODEL_AGGREGATION_MIN:
        record.output.score = Math.min(
          ...record.groups.map((i) => i.score ?? 1)
        );
        break;
      default:
        break;
    }
  }
}

export function getMaturityLevel(record: IEvaluationModel): string {
  var level = "";
  if (record && record.groups.length > 0) {
    // Consider the maturity at base level to start with
    var maturityIndex = 0;
    for (var i = 0; i < record.groups.length; i++) {
      // At any particular level, score should be minimum Qualifying Score, if not return back
      if (record.groups[i].score < record.minQualifyingScore) {
        break;
      } else {
        maturityIndex = i;
      }
    }

    level = record.groups[maturityIndex].name;
  }
  return level;
}

export function useAttachment(url: string | undefined) {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    url &&
      fetch(url + "&redirect=false")
        .then((res) => (res.ok ? res.json() : { url: "" }))
        .then((json) => {
          if (json && json.url) {
            fetch(json.url)
              .then((res) => (res.ok ? res.text() : ""))
              .then((text) => {
                setIsLoading(false);
                setResponse(text);
              });
          } else {
            setIsLoading(false);
            setResponse("# Not available");
          }
        })
        .catch(() => {
          setIsLoading(false);
          setResponse("# Not available");
        });
  }, [url]);
  return { response, isLoading };
}

const LOOKUP_API = API_PATH + "/" + DATAPROVIDER_LOOKUP + "/";

export function useProfileLookup(id: string | undefined) {
  return useResourceLookup<IUser>(RESOURCE_PATH.PEOPLE, id);
}

export function useResourceLookup<TLookup>(resource: string | undefined, id: string | undefined) {
  const [dataObj, setDataObj] = useState<TLookup>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    resource && resource !== "" &&
      id && id !== "" &&
      apiCallWithMutex(LOOKUP_API + resource + "/" + id).then((res) => {
        setIsLoading(false);
        setDataObj(res);
      });
  }, [id, resource]);
  return { dataObj, isLoading };
}

export function useUrlFromBlob(url: string | undefined) {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    url &&
      fetch(url + "&redirect=false")
        .then((res) => (res.ok ? res.json() : { url: "" }))
        .then((json) => {
          if (json && json.url) {
            setIsLoading(false);
            setResponse(json.url);
          } else {
            setIsLoading(false);
            setResponse("# Not available");
          }
        })
        .catch(() => {
          setIsLoading(false);
          setResponse("# Not available");
        });
  }, [url]);
  return { response, isLoading };
}

export function useAuthZ() {
  const [authZ, setAuthZ] = useState<IAuthZ[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAuthZ().then((res) => {
      setIsLoading(false);
      setAuthZ(res);
    });
  }, []);
  return { authZ, isLoading };
}

export function useNotifications<TData extends BaseRecord = BaseRecord>(resourceName: string = RESOURCE_PATH.WORKLIST) {
  return useTable<TData, HttpError, IWorklistFilterVariables>({
    dataProviderName: DATAPROVIDER_WORKLIST,
    resource: resourceName,
    initialPageSize: 100,
    initialSorter: [
      {
        field: "stateManager.assignedAt",
        order: "desc"
      },
    ],
    syncWithLocation: false,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, state } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      return filters;
    }
  });
}


export function parseTimeZone(userTime: string): string {
  let result = "";
  if (userTime) {
    const timeZoneText = userTime.match(/\(([A-Za-z\s].*)\)/);
    if (timeZoneText) {
      result = timeZoneText[1];
    }
  }
  return result;
}

export declare type TimeZoneResult = {
  text: string;
  relativeType: "None" | "Equal" | "Ahead" | "Behind";
};

export function parseRelativeTimeZone(
  userTime: string | undefined
): TimeZoneResult {
  const result: TimeZoneResult = {
    text: "",
    relativeType: "None",
  };
  if (userTime) {
    /* eslint-disable no-useless-escape */
    const userTimeZone = userTime.match(/([-\+][0-9]+)\s/);
    /* eslint-disable no-useless-escape */
    const currentTimeZone = new Date().toString().match(/([-\+][0-9]+)\s/);

    if (userTimeZone && currentTimeZone) {
      //const diff = parseInt(userTimeZone[0]) - parseInt(currentTimeZone[0]);
      const diff = parseInt(currentTimeZone[0]) - parseInt(userTimeZone[0]);
      const hourPart = Math.abs(diff).toString().slice(0, -2);
      const diffFormat =
        (hourPart ? hourPart + "h " : "") + diff.toString().slice(-2) + "m";
      if (diff === 0) {
        result.text = "Same time zone as you ";
        result.relativeType = "Equal";
      } else if (diff < 0) {
        result.text = "You're " + diffFormat + " behind ";
        result.relativeType = "Behind";
      }
      if (diff > 0) {
        result.text = "You're " + diffFormat + " ahead ";
        result.relativeType = "Ahead";
      }
    }
  }
  return result;
}

export function getLocationCountryString(
  location: string,
  country?: string
): string {
  let textString = "---";
  if (country) {
    textString = location + ", " + country;
  } else {
    textString = location;
  }
  return textString;
}

export function buildAttachmentUrl(
  resource: string,
  id: string | undefined,
  filepath: string | undefined
): string | undefined {
  if (resource && id && filepath) {
    return [ATTACHMENT, resource, id].join("/") + "?filepath=" + filepath;
  }
  return undefined;
}

export function stateManagerToMermaid(stateManager: IStateManager): string {
  // Initialize with mermaid string
  let mermaidDiagram = "flowchart TB \n";
  let previousState = "";
  let stateDetails = "";

  try {
    const nodes = arrayExtensions.sortBy("beginTS", stateManager.workflow);

    nodes.forEach(function (node, indx) {
      stateDetails = node.stateChangedBy
        ? `Acted by: ${node.stateChangedBy} \n`
        : "";
      stateDetails += node.beginTS
        ? `on: ${dayjs(node.beginTS).format("LLLL")} \n`
        : "";
      stateDetails += arrayExtensions.validateArray(node.assignedTo)
        ? `assigned to: ${node.assignedTo.join(";")} \n`
        : "";
      stateDetails += node.comments ? `with comments: ${node.comments}` : "";

      // Process the stateManager Nodes
      if (indx === 0) {
        mermaidDiagram += `AA[[Entity Creation]]:::node --> |"${stateDetails}"| ${getNextAlphabet(
          indx
        )}((${stringExtensions.capitalize(node.state)})) \n`;
      } else {
        mermaidDiagram += `${previousState}:::node --> |"${stateDetails}"| ${getNextAlphabet(
          indx
        )}((${stringExtensions.capitalize(node.state)})) \n`;
      }
      previousState = `${getNextAlphabet(indx)}((${stringExtensions.capitalize(
        node.state
      )}))`;
    });

    mermaidDiagram += `classDef node fill:#000, color:#fff; \n`;
  } catch (ex) {
    mermaidDiagram += `AA[[Error !!! Unable to generate mermaid view]]:::errnode \n`;
    mermaidDiagram += `classDef errnode fill:#ff0000, color:#fff; \n`;
  }

  return mermaidDiagram;
}

export function statesToMermaid(states: IState[]): string {
  // Initialize with mermaid string
  let mermaidDiagram = "stateDiagram-v2 \n";
  mermaidDiagram += "direction LR \n";

  try {
    const nodes = arrayExtensions.sortArrayByOrder(states);

    nodes.forEach(function (node, indx) {
      // Process the state Nodes
      if (indx === 0) {
        mermaidDiagram += `[*] --> ${stringExtensions.capitalize(
          node.state
        )} \n`;
      } else if (node.nextStates && node.nextStates.length === 0) {
        mermaidDiagram += `${stringExtensions.capitalize(
          node.state
        )} --> [*] \n`;
      }

      // Draw for each possible state swicthes
      node.nextStates.forEach(function (st: nextState) {
        mermaidDiagram += `${stringExtensions.capitalize(
          node.state
        )} --> ${stringExtensions.capitalize(st.stateId)} : ${st.buttonText} \n`;
      });
    });
  } catch (ex) {
    mermaidDiagram += `error: !!! Unable to generate mermaid view !!! \n`;
  }

  return mermaidDiagram;
}

export function getNextAlphabet(index: number, capital: boolean = true) {
  return String.fromCharCode(index + (capital ? 65 : 97));
}

export function getCookieByName(cookieName: string) {
  let name = cookieName + "=";
  let spli = document.cookie.split(";");

  for (var j = 0; j < spli.length; j++) {
    let char = spli[j];
    while (char.charAt(0) === " ") {
      char = char.substring(1);
    }
    if (char.indexOf(name) === 0) {
      return char.substring(name.length, char.length);
    }
  }
  return "";
}

export function roundToNearesetFive(roundNumber: number): number {
  if (roundNumber === 0) {
    return roundNumber;
  } else {
    return Math.round(roundNumber / 5.0) * 5;
  }
}

export function arrayJoinString(
  inputArray: string[],
  joinString: string = ", ",
  defaultString: string = "---"
): string {
  if (arrayExtensions.validateArray(inputArray)) {
    return inputArray.join(joinString);
  }
  return defaultString;
}

export function getGUID() {
  var uuidValue = "",
    k,
    randomValue;
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0;

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += "-";
    }
    uuidValue += (
      k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue
    ).toString(16);
  }
  return uuidValue;
}

export function validateAddedTags(
  addedTags: string[],
  blockedTags: string[]
): string[] {
  let blockTags: string[] = [];

  if (
    arrayExtensions.validateArray(addedTags) &&
    arrayExtensions.validateArray(blockedTags)
  ) {
    blockTags = addedTags.filter((element) =>
      blockedTags.some(
        (blockedWord) => element.toLowerCase() === blockedWord.toLowerCase()
      )
    );
  }
  return blockTags;
}

export function validateOnlyNumbersArray(inputArray: any[]): boolean {
  return inputArray.every((element) => {
    return !isNaN(element) && element > 0;
  });
}

export function validateRGSIDs(inputArray: any[]): boolean {
  return inputArray.every((element) => {
    if (isNaN(element)) return false;
    let elemStr = element.toString();
    let elemNbr = +element;
    return (
      Number.isInteger(elemNbr) &&
      elemNbr > 0 &&
      elemStr.length === 7 &&
      (elemStr.startsWith("8") || elemStr.startsWith("9"))
    );
  });
}

export function formatMS(
  ms: number,
  shorten: boolean = true,
  precise: boolean = true,
  limit: number = 99,
  split: boolean = false,
  splitShorten: boolean = true
) {
  const long: string[] = [
    "millisecond",
    "second",
    "minute",
    "hour",
    "day",
    "year",
  ];
  const short: string[] = ["ms", "sec", "min", "hr", "day", "year"];
  const calcs: number[] = [1000, 60, 60, 24, 365];
  let formatString: string = "";
  let i: number = 0;
  let remainder: number = 0;
  let splittable: boolean = false;
  let preciseable: boolean = precise;

  //Case: Amount of time passed is less than 1 minute
  if (ms < calcs[0] * calcs[1]) return "<1 " + (shorten ? short[2] : long[2]);

  //Calcs
  for (i = 0; ms / calcs[i] > 1 && i < limit && calcs[i]; i++) {
    ms /= calcs[i];
  }
  remainder = ms - Math.floor(ms);

  //Formatting
  if (split) {
    for (let j = i - 1; calcs[j]; j--) {
      remainder *= calcs[j];
    }
    //if remainder is >= 1 minute, you can split the string
    if (remainder >= 60000) {
      splittable = true;
      preciseable = false;

      if (splitShorten) {
        shorten = true;
      } else {
        shorten = false;
      }
    } else {
      //Round up if more than 30000 ms have passed
      if (remainder > 30000) {
        ms += 1;
      }
    }
  } else {
    //Round up if remainder is at least half of the main value
    if (remainder >= 0.5) ms += 1;
  }

  preciseable
    ? (formatString += ms.toFixed(2))
    : (formatString += Math.floor(ms).toFixed(0));
  formatString += " ";
  shorten ? (formatString += short[i]) : (formatString += long[i]);
  if (!preciseable && ms.toFixed(0) !== "1") formatString += "s";
  if (preciseable && ms.toFixed(2) !== "1.00") formatString += "s";

  if (splittable) {
    formatString = remainder
      ? formatString + " & " + formatMS(remainder, shorten, precise, limit)
      : formatString;
  }

  return formatString;
}

export function timeDiff(x: string, y: string) {
  let day1 = dayjs(Date.now());
  let day2 = dayjs(Date.now());
  let diff: number;

  //Default to Current Date/Time if empty
  if (x !== "") day1 = dayjs(x);
  if (y !== "") day2 = dayjs(y);

  //Default to always positive
  if (dayjs(x) > dayjs(y)) {
    diff = day1.diff(day2, "ms", true);
  } else {
    diff = day2.diff(day1, "ms", true);
  }

  return diff;
}

export function calcMinutes(fromTime: string, toTime: string) {
  const ft = dayjs(`2000-01-01 ${fromTime}`);
  const tt = dayjs(`2000-01-01 ${toTime}`);
  const mins = tt.diff(ft, "minutes", true);
  return mins
}

export function generateGroupFetchID(groupName: string | undefined): string {
  let baseString: string = `/${FETCH_ACTION}?operation=groupMembersSelectList`;
  if (groupName) {
    return groupName + baseString;
  }
  return baseString;
}

export function isValidUrl(urlString: string): boolean {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
}

export function getRedirectionURL(hashURLString: string): string | undefined {
  if (
    hashURLString &&
    hashURLString.length > 0 &&
    hashURLString.includes(POST_LOGIN_REDIRECT)
  ) {
    let encodedURL = hashURLString.split(POST_LOGIN_REDIRECT)?.[1];
    if (encodedURL) {
      return decodeURI(encodedURL);
    }
  }
  return undefined;
}

export function doesExist(member: any) {
  if (Array.isArray(member) && member.length === 0) {
    return false
  }
  if (member !== undefined && member !== null && member !== "") {
    return true;
  }
  return false;
}

export function showDash(member: any[] | string | null | undefined) {
  if (doesExist(member)) {
    return member;
  }
  return "---";
}

export function formatImportDataType(dataType: string, fieldValue: string, seperator: string = ',') {
  if (fieldValue) {
    if (dataType === "array") {
      return fieldValue.toString()?.trim()?.split(seperator);
    }
    else if (dataType === "date") {
      return dayjs(fieldValue).format("MM/DD/YYYY");
    }
    else if (dataType === "number") {
      let numericValue = Number(fieldValue);
      return isNaN(numericValue) ? 0 : numericValue;
    }
    else {
      return fieldValue.toString()?.trim();
    }
  }
  return fieldValue;
}

export function getStateDisplayName(entityState: string, entityMetadataState: IState[]): string {
  let displayName = entityState;
  const state = entityMetadataState.find((state) => state.state === entityState);
  if (state) {
    displayName = state.displayName;
  }
  return displayName;
}
// Generate an integer Array containing an arithmetic progression. A port of
// the native Python `range()` function. See
// [the Python documentation](http://docs.python.org/library/functions.html#range).
export function range(start: number, stop: number, step: number) {
  if (stop == null) {
    stop = start || 0;
    start = 0;
  }
  step = step || 1;

  var length = Math.max(Math.ceil((stop - start) / step), 0);
  var range = Array(length);

  for (var idx = 0; idx < length; idx++, start += step) {
    range[idx] = start;
  }

  return range;
};

export function dateRange(startDate: Dayjs, endDate: Dayjs) {
  let diff = endDate.diff(startDate, "days");
  return range(0, diff + 1, 1).map((i) => startDate.add(i, "day").format("YYYY-MM-DD"));
}

export function extractTime(dateString: string) {
  return dateString && dateString.substring(11, 16);
}

export function getSkills(requiredSkills: string[], goodToHaveSkills: string[], skillCategory: string): string[] {
  let skills = []

  // Capture Required Skills
  if (arrayExtensions.validateArray(requiredSkills)) {
    requiredSkills.forEach((skill) => {
      skills.push(skill.toString().trim());
    })
  }
  // Capture Good to have Skills
  if (arrayExtensions.validateArray(goodToHaveSkills)) {
    goodToHaveSkills.forEach((skill) => {
      skills.push(skill.toString().trim());
    })
  }

  // Append Skill Category
  if (skillCategory) {
    skills.push(skillCategory.toString().trim());
  }

  // Remove duplicated and return final array
  return arrayExtensions.arrayRemoveDuplicate(skills);
}

export function appendToAppTitle(titleToAdd: string) {
  if (titleToAdd) {
    const titleSplit = document.title.split(" | ").reverse();
    if (titleSplit.length > 1) {
      document.title = titleToAdd + " | " + titleSplit[1] + " | " + titleSplit[0];
    }
    else if (titleSplit.length === 1) {
      document.title = titleToAdd + " | " + titleSplit[0];
    }
  }
}

export function syncMetricValueList(metricList: IMetric[], metricValues: IMetricValue[], defaultMetricValue: string | boolean = ""): IRecordMetricValue[] {
  // Sync up the metric with the metric values
  const result: IRecordMetricValue[] = [];
  (arrayExtensions.sortBy("order", metricList) as IMetric[])
    .forEach(kr => {
      const recordMetric = (metricValues ?? []).find(r => r.id === kr.id);
      result.push({
        metric: kr,
        metricValue: recordMetric
          ? recordMetric
          : {
            id: kr.id,
            value: defaultMetricValue,
          }
      });
    });
  return result;
}

// Assigning null value to a field makes sure that it passes to update API. Else the value doesn't get updated. 
export function handleSelectChange(form: FormInstance, value: string | { value: string, label: string }, fieldName: string): void {
  if (value === undefined) {
    form.setFieldsValue({ [fieldName]: null })
  }
}