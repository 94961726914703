import { Icon, Icons } from "@pankod/refine-antd";
import { ResourceProps } from "@pankod/refine-core";
import { About } from "pages/about/about";
import { ArticleCreate, ArticleEdit, ArticleList, ArticleShow } from "pages/accelerators/article";
import { AskTheExpert } from "pages/askexpert/askexpert";
import { AssetCreate, AssetEdit, AssetList, AssetShow } from "pages/asset";
import { AssessmentList } from "pages/delivery/assessment";
import { AllocationList } from "pages/delivery/associatemapping";
import { AllocationCreate } from "pages/delivery/associatemapping/create";
import { ContractEdit, ContractList } from "pages/delivery/contracts";
import { EngagementCreate, EngagementEdit, EngagementList, EngagementShow } from "pages/delivery/engagements";
import { EvaluationEdit, EvaluationList, EvaluationShow } from "pages/delivery/evaluation";
import { EvaluationModelList } from "pages/delivery/evaluationmodel/list";
import { EvaluationModelShow } from "pages/delivery/evaluationmodel/show";
import { EventCreate, EventEdit, EventList, EventShow } from "pages/events";
import { GroupCreate, GroupEdit, GroupList, GroupShow } from "pages/groups";

import { ABMDeskList, AnnouncementList, AspireList, SecurityInfoList } from "pages/infocorner";
import { PodcastList } from "pages/infocorner/podcast/list";
import { TagsReviewList } from "pages/manage/tags/list";
import { PeopleList, PeopleShow } from "pages/people";
import { ProfileEdit, ProfileList, Worklist } from "pages/profile";
import { Notifications } from "pages/profile/myNotifications";
import { CandidateCreate, CandidateEdit, CandidateEvaluationEdit, CandidateEvaluationShow, CandidateList, CandidateShow, CreateResourcingRequest, EditResourcingRequest, PositionEdit, PositionList, PositionShow, ResourcingRequestList, ResourcingRequestShow } from "pages/resourcing";
import { AvailabilityList } from "pages/spaces/availabilities/list";

import { ReservationCreate, ReservationEdit, ReservationList, ReservationShow } from "pages/spaces/reservations";
import { RoomCreate, RoomEdit, RoomList, RoomShow } from "pages/spaces/rooms";
import { SystemList } from "pages/system";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users";
import { RESOURCE_PATH } from "scripts/site";

import { GenAISVG } from "components/svgs/genAISvg";
import { PromptSVG } from "components/svgs/promptSvg";
import { OfferingCreate, OfferingEdit, OfferingList, OfferingShow } from "pages/accelerators/offering";
import { PromptCreate, PromptEdit, PromptList, PromptShow } from "pages/accelerators/prompt";
import { ScenarioCreate, ScenarioEdit, ScenarioList, ScenarioShow } from "pages/accelerators/scenario";
import { AiSummaryList } from "pages/aisummary/list";
import { EngagementProgramEdit, EngagementProgramList, EngagementProgramShow } from "pages/delivery/engagementprogram";
import { EngagementSolutionCreate, EngagementSolutionEdit, EngagementSolutionList, EngagementSolutionShow } from "pages/delivery/engagementsolution";
import { ProgramList, ProgramShow } from "pages/delivery/program";
import { ProcessList } from "pages/infocorner/process/list";
import { IntakeActivityList } from "pages/intake/activity";
import { IntakeCreate } from "pages/intake/create";
import { IntakeEdit } from "pages/intake/edit";
import { IntakeList } from "pages/intake/list";
import { IntakeShow } from "pages/intake/show";
import { CountryCreate, CountryEdit, CountryList, CountryShow } from "pages/manage/country";
import { LocationCreate, LocationEdit, LocationList, LocationShow } from "pages/manage/location";
import { Request } from "pages/profile/myRequest";

export const heliumResources: ResourceProps[] = [
    {
        name: "home",
        key: "home",
        options: { label: "Home" },
        icon: <Icons.HomeOutlined />
    },
    {
        name: RESOURCE_PATH.PROFILE,
        key: RESOURCE_PATH.PROFILE,
        list: ProfileList,
        edit: ProfileEdit,
        parentName: "home",
        options: { label: "My Profile" },
        icon: <Icons.AppstoreOutlined />
    },
    {
        name: RESOURCE_PATH.WORKLIST,
        key: RESOURCE_PATH.WORKLIST,
        parentName: "home",
        list: Worklist,
        options: { label: "My Worklist" },
        icon: <Icons.BellOutlined />
    },
    {
        name: RESOURCE_PATH.REQUEST,
        key: RESOURCE_PATH.REQUEST,
        parentName: "home",
        list: Request,
        options: { label: "My Request" },
        icon: <Icons.BarsOutlined />
    },
    {
        name: RESOURCE_PATH.NOTIFICATIONS,
        key: RESOURCE_PATH.NOTIFICATIONS,
        parentName: "home",
        list: Notifications,
        options: { label: "My Notifications" },
        icon: <Icons.NotificationOutlined />
    },
    {
        name: RESOURCE_PATH.IQ,
        key: RESOURCE_PATH.IQ,
        list: AiSummaryList,
        canDelete: false,
        options: { label: "Helium IQ" },
        icon: <Icon component={GenAISVG}></Icon>
    },

    {
        name: "accelerators",
        key: "accelerators",
        options: { label: "Accelerators" },
        icon: <Icons.RocketOutlined />
    },
    // {
    //     name: RESOURCE_PATH.STORIES,
    //     key: RESOURCE_PATH.STORIES,
    //     list: StoriesList,
    //     parentName: "accelerators",
    //     options: { label: "Stories" },
    //     icon: <Icons.FileTextOutlined />
    // },
    {
        name: RESOURCE_PATH.PROGRAM,
        key: RESOURCE_PATH.PROGRAM,
        list: ProgramList,
        show: ProgramShow,
        parentName: "accelerators",
        canDelete: false,
        icon: <Icons.AimOutlined />
    },
    {
        name: RESOURCE_PATH.ARTICLE,
        key: RESOURCE_PATH.ARTICLE,
        list: ArticleList,
        create: ArticleCreate,
        edit: ArticleEdit,
        show: ArticleShow,
        parentName: "accelerators",
        options: { label: "Solutions" },
        icon: <Icons.SolutionOutlined />
    },
    {
        name: RESOURCE_PATH.PROMPT,
        key: RESOURCE_PATH.PROMPT,
        list: PromptList,
        create: PromptCreate,
        edit: PromptEdit,
        show: PromptShow,
        parentName: "accelerators",
        //options: { label: "PROMPT" },
        icon: <Icon component={PromptSVG}></Icon>
    },
    {
        name: RESOURCE_PATH.SCENARIO,
        key: RESOURCE_PATH.SCENARIO,
        //list: MetadataListPage,
        list: ScenarioList,
        create: ScenarioCreate,
        edit: ScenarioEdit,
        show: ScenarioShow,
        parentName: "accelerators",
        //options: { label: "Scenario", pageHeader: "Scenario" },
        icon: <Icons.BlockOutlined />
    },
    {
        name: RESOURCE_PATH.OFFERING,
        key: RESOURCE_PATH.OFFERING,
        //list: MetadataListPage,
        list: OfferingList,
        create: OfferingCreate,
        edit: OfferingEdit,
        show: OfferingShow,
        parentName: "accelerators",
        icon: <Icons.BlockOutlined />
    },
    {
        name: "engage",
        key: "engage",
        options: { label: "Engage" },
        icon: <Icons.BuildOutlined />
    },
    {
        name: RESOURCE_PATH.INTAKE,
        key: RESOURCE_PATH.INTAKE,
        parentName: "engage",
        options: { label: "Engage AIT" },
        list: IntakeList,
        create: IntakeCreate,
        edit: IntakeEdit,
        show: IntakeShow,
        icon: <Icons.BuildOutlined />
    },
    {
        name: RESOURCE_PATH.INTAKE_ACTIVITY,
        key: RESOURCE_PATH.INTAKE_ACTIVITY,
        list: IntakeActivityList,
        parentName: "engage",
        canDelete: false,
        options: { label: "Activities" },
        icon: <Icons.CheckCircleOutlined />
    },
    // {
    //     name: RESOURCE_PATH.CHAT,
    //     key: RESOURCE_PATH.CHAT,
    //     list: ChatList,
    //     parentName: "accelerators",
    //     // options: { label: "Chat" },
    //     icon: <Icons.CommentOutlined />
    // },
    {
        name: "delivery",
        key: "delivery",
        options: { label: "Delivery" },
        icon: <Icons.DeploymentUnitOutlined />
    },
    {
        name: RESOURCE_PATH.ENGAGEMENT,
        key: RESOURCE_PATH.ENGAGEMENT,
        list: EngagementList,
        show: EngagementShow,
        create: EngagementCreate,
        edit: EngagementEdit,
        parentName: "delivery",
        canDelete: false,
        icon: <Icons.GlobalOutlined />
    },
    {
        name: RESOURCE_PATH.CONTRACT,
        key: RESOURCE_PATH.CONTRACT,
        list: ContractList,
        edit: ContractEdit,
        parentName: "delivery",
        canDelete: false,
        icon: <Icons.FileProtectOutlined />
    },
    {
        name: RESOURCE_PATH.ASSOCIATEMAPPING,
        key: RESOURCE_PATH.ASSOCIATEMAPPING,
        list: AllocationList,
        create: AllocationCreate,
        options: { label: "Associates" },
        parentName: "delivery",
        canDelete: false,
        icon: <Icons.SolutionOutlined />
    },
    {
        name: RESOURCE_PATH.ASSESSMENT,
        key: RESOURCE_PATH.ASSESSMENT,
        list: AssessmentList,
        parentName: "delivery",
        canDelete: false,
        icon: <Icons.FormOutlined />
    },
    {
        name: RESOURCE_PATH.ENGAGEMENTPROGRAM,
        key: RESOURCE_PATH.ENGAGEMENTPROGRAM,
        list: EngagementProgramList,
        edit: EngagementProgramEdit,
        show: EngagementProgramShow,
        parentName: "delivery",
        canDelete: true,
        options: { label: "Engagement Program" },
        icon: <Icons.SolutionOutlined />
    },
    {
        name: RESOURCE_PATH.ENGAGEMENTSOLUTION,
        key: RESOURCE_PATH.ENGAGEMENTSOLUTION,
        create: EngagementSolutionCreate,
        list: EngagementSolutionList,
        edit: EngagementSolutionEdit,
        show: EngagementSolutionShow,
        parentName: "delivery",
        canDelete: true,
        options: { label: "Engagement Solution" },
        icon: <Icons.SolutionOutlined />
    },
    {
        name: RESOURCE_PATH.EVALUATION,
        key: RESOURCE_PATH.EVALUATION,
        list: EvaluationList,
        edit: EvaluationEdit,
        show: EvaluationShow,
        parentName: "delivery",
        canDelete: true,
        icon: <Icons.ContainerOutlined />
    },
    {
        name: RESOURCE_PATH.EVALUATIONMODEL,
        key: RESOURCE_PATH.EVALUATIONMODEL,
        list: EvaluationModelList,
        show: EvaluationModelShow,
        options: { label: "Eval. Models" },
        parentName: "delivery",
        canDelete: false,
        icon: <Icons.FormOutlined />
    },
    {
        name: "resourcing",
        key: "resourcing",
        options: { label: "Resourcing" },
        icon: <Icons.UsergroupAddOutlined />
    },

    {
        name: RESOURCE_PATH.RESOURCINGREQUEST,
        key: RESOURCE_PATH.RESOURCINGREQUEST,
        create: CreateResourcingRequest,
        edit: EditResourcingRequest,
        list: ResourcingRequestList,
        show: ResourcingRequestShow,
        parentName: "resourcing",
        options: { label: "Requests" },
        icon: <Icons.SkinOutlined />
    },
    {
        name: RESOURCE_PATH.POSITION,
        key: RESOURCE_PATH.POSITION,
        edit: PositionEdit,
        list: PositionList,
        show: PositionShow,
        canDelete: false,
        parentName: "resourcing",
        options: { label: "Positions" },
        icon: <Icons.TeamOutlined />
    },
    {
        name: RESOURCE_PATH.CANDIDATE,
        key: RESOURCE_PATH.CANDIDATE,
        create: CandidateCreate,
        edit: CandidateEdit,
        list: CandidateList,
        show: CandidateShow,
        parentName: "resourcing",
        options: { label: "Candidates" },
        icon: <Icons.UserSwitchOutlined />
    },
    {
        name: RESOURCE_PATH.CANDIDATEEVALUATION,
        key: RESOURCE_PATH.CANDIDATEEVALUATION,
        show: CandidateEvaluationShow,
        edit: CandidateEvaluationEdit,
        parentName: "resourcing",
        options: { label: "Candidate Evaluation" },
        icon: <Icons.UserSwitchOutlined />
    },
    {
        name: "spaces",
        key: "spaces",
        options: { label: "Spaces" },
        icon: <Icons.CalendarOutlined />
    },
    {
        name: RESOURCE_PATH.ROOM,
        key: RESOURCE_PATH.ROOM,
        options: { label: "Rooms" },
        parentName: "spaces",
        list: RoomList,
        create: RoomCreate,
        edit: RoomEdit,
        show: RoomShow,
        canDelete: true,
        icon: <Icons.BorderOutlined />
    },
    {
        name: RESOURCE_PATH.RESERVATION,
        key: RESOURCE_PATH.RESERVATION,
        options: { label: "Reservations" },
        parentName: "spaces",
        list: ReservationList,
        create: ReservationCreate,
        edit: ReservationEdit,
        show: ReservationShow,
        canDelete: true,
        icon: <Icons.CarryOutOutlined />
    },
    {
        name: RESOURCE_PATH.AVAILABILITY,
        key: RESOURCE_PATH.AVAILABILITY,
        options: { label: "Availability" },
        parentName: "spaces",
        list: AvailabilityList,
        // show: ReservationShow,
        canDelete: false,
        icon: <Icons.ScheduleOutlined />
    },
    {
        name: RESOURCE_PATH.PEOPLE,
        key: RESOURCE_PATH.PEOPLE,
        list: PeopleList,
        show: PeopleShow,
        canDelete: false,
        options: { label: "People" },
        icon: <Icons.TeamOutlined />
    },
    {
        name: RESOURCE_PATH.ASSET,
        key: RESOURCE_PATH.ASSET,
        list: AssetList,
        create: AssetCreate,
        edit: AssetEdit,
        show: AssetShow,
        canDelete: true,
        icon: <Icons.LaptopOutlined />
    },

    {
        name: RESOURCE_PATH.GROUPS,
        key: RESOURCE_PATH.GROUPS,
        options: { label: "Groups" },
        list: GroupList,
        create: GroupCreate,
        edit: GroupEdit,
        show: GroupShow,
        canDelete: true,
        icon: <Icons.GroupOutlined />
    },

    {
        name: RESOURCE_PATH.EVENT,
        key: RESOURCE_PATH.EVENT,
        options: { label: "Events" },
        list: EventList,
        create: EventCreate,
        edit: EventEdit,
        show: EventShow,
        canDelete: true,
        icon: <Icons.ClusterOutlined />
    },
    {
        name: RESOURCE_PATH.ASKTHEEXPERT,
        key: RESOURCE_PATH.ASKTHEEXPERT,
        list: AskTheExpert,
        canDelete: false,
        options: { label: "Ask the Expert" },
        icon: <Icons.PhoneOutlined />
    },
    {
        name: "info-board",
        key: "info-board",
        options: { label: "Info Corner" },
        icon: <Icons.InfoCircleOutlined />
    },
    {
        name: RESOURCE_PATH.INFO_PROCESS,
        key: RESOURCE_PATH.INFO_PROCESS,
        list: ProcessList,
        parentName: "info-board",
        options: { label: "Process" },
        icon: <Icons.ApartmentOutlined />
    },
    {
        name: RESOURCE_PATH.INFO_ABMDESK,
        key: RESOURCE_PATH.INFO_ABMDESK,
        list: ABMDeskList,
        parentName: "info-board",
        options: { label: "ABM Desk" },
        icon: <Icons.CommentOutlined />
    },
    {
        name: RESOURCE_PATH.INFO_ANNOUNCEMENTS,
        key: RESOURCE_PATH.INFO_ANNOUNCEMENTS,
        list: AnnouncementList,
        parentName: "info-board",
        options: { label: "Announcements" },
        icon: <Icons.SoundOutlined />
    },
    {
        name: RESOURCE_PATH.INFO_ASPIRE,
        key: RESOURCE_PATH.INFO_ASPIRE,
        list: AspireList,
        parentName: "info-board",
        options: { label: "ASPIRE" },
        icon: <Icons.ReadOutlined />
    },
    {
        name: RESOURCE_PATH.INFO_PODCAST,
        key: RESOURCE_PATH.INFO_PODCAST,
        list: PodcastList,
        parentName: "info-board",
        options: { label: "Podcasts" },
        icon: <Icons.AudioOutlined />
    },
    {
        name: RESOURCE_PATH.INFO_SECURITY,
        key: RESOURCE_PATH.INFO_SECURITY,
        list: SecurityInfoList,
        parentName: "info-board",
        options: { label: "Security" },
        icon: <Icons.SecurityScanOutlined />
    },

    {
        name: "manage",
        key: "manage",
        options: { label: "Manage" },
        icon: <Icons.SettingOutlined />
    },
    {
        name: RESOURCE_PATH.REVIEW_TAGS,
        key: RESOURCE_PATH.REVIEW_TAGS,
        list: TagsReviewList,
        parentName: "manage",
        options: { label: "Review Tags" },
        canDelete: false,
        icon: <Icons.VerifiedOutlined />
    },
    {
        name: RESOURCE_PATH.USERS,
        key: RESOURCE_PATH.USERS,
        list: UserList,
        create: UserCreate,
        edit: UserEdit,
        show: UserShow,
        canDelete: true,
        parentName: "manage",
        icon: <Icons.TeamOutlined />
    },
    {
        name: RESOURCE_PATH.COUNTRY,
        key: RESOURCE_PATH.COUNTRY,
        list: CountryList,
        create: CountryCreate,
        edit: CountryEdit,
        show: CountryShow,
        canDelete: false,
        parentName: "manage",
        options: { label: "Countries" },
        icon: <Icons.PushpinOutlined />
    },
    {
        name: RESOURCE_PATH.LOCATION,
        key: RESOURCE_PATH.LOCATION,
        list: LocationList,
        create: LocationCreate,
        edit: LocationEdit,
        show: LocationShow,
        canDelete: false,
        parentName: "manage",
        options: { label: "Locations" },
        icon: <Icons.PushpinOutlined />
    },
    {
        name: RESOURCE_PATH.ABOUT,
        key: RESOURCE_PATH.ABOUT,
        list: About,
        canDelete: false,
        options: { label: "About" },
        icon: <Icons.InfoOutlined />
    },
    {
        name: RESOURCE_PATH.SYSTEM,
        key: RESOURCE_PATH.SYSTEM,
        list: SystemList,
        options: { label: "System" },
        canDelete: false,
        icon: <Icons.CodeOutlined />
    }
];